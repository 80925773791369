import React from 'react';

const productLabelsAndNames = [
  { label: 'GTIN', name: 'gtin' },
  { label: 'Brand name', name: 'brand_name', parseObject: true },
  { label: 'Product description', name: 'product_description', parseObject: true },
  { label: 'Company name', name: 'company_name', parseObject: true },
  { label: 'Product image URL', name: 'product_image' },
  { label: 'Net content', name: 'net_content', description: 'unit_of_measure', parseNetContent: true },
  { label: 'MO name', name: 'mo_name' },
  { label: 'Target market', name: 'target_market', parseTarget: true },
  { label: 'GPC Code', name: 'gpc_code' },
  { label: 'GPC Name', name: 'gpc_name' },
];

const tableDescription = (searchResponse, element) => {
  const elementName = searchResponse?.[element.name];
  const elementDescription = searchResponse?.[element.description];

  if (elementName) {
    if (elementDescription) {
      return `${elementName} ${elementDescription}`;
    }
    if (typeof elementName === 'object') {
      let errorDescription = '';
      Object.keys(elementName)?.map(key => {
        errorDescription += `(${key}) ${elementName[key]} `;
        return elementName[key];
      });
      return errorDescription;
    }
    return elementName;
  } else {
    return <div className="unknown">Unknown</div>;
  }
};
const GTINView = ({ searchResponse }) => {
  const renderLocalizedData = ({ element }) => {
    const jsonValue = searchResponse?.[element.name] || {};
    return Object.keys(jsonValue).map(key => (
      <tr>
        <td>{`${element.label} (${key})`}</td>
        <td>{jsonValue[key] || <div className="unknown">Unknown</div>}</td>
      </tr>
    ));
  };

  const renderNetContent = ({ element }) => {
    const NetContentValues = searchResponse?.[element.name] || [];
    return NetContentValues?.map(value => {
      return <tr> {`${value['net_content']} ${value['unit_of_measure']}`} </tr>;
    });
  };
  const renderTargetMarket = ({ element }) => {
    const NetContentValues = searchResponse?.[element.name] || [];
    return NetContentValues?.map((item, index) => {
      return `${item}${index !== NetContentValues.length - 1 ? ', ' : ' '}`;
    });
  };
  const renderObject = ({ element }) => {
    const NetContentValues = searchResponse?.[element.name] || [];
    return Object.keys(NetContentValues)?.map(value => {
      return <tr> {`(${value})  ` + NetContentValues[value]} </tr>;
    });
  };
  const renderimagesURLS = element => {
    const NetContentValues = searchResponse?.[element.name] || [];
    return Object.keys(NetContentValues)?.map(value => {
      return (
        <tr style={{ wordBreak: 'unset', lineBreak: 'anywhere' }}>
          ({value}) {'   '}
          <a href={NetContentValues[value]} target="_blank" class="button" rel="noreferrer">
            {NetContentValues[value]}
          </a>
        </tr>
      );
    });
  };

  const renderTableData = ({ data }) =>
    data.map((element, index) => (
      <div key={index} className="table-style">
        {element.parseNetContent ? (
          <tr>
            <td>{element.label}</td>
            <td>{renderNetContent({ element })}</td>
          </tr>
        ) : element.parseObject ? (
          <tr>
            <td>{element.label}</td>
            <td>{renderObject({ element })}</td>
          </tr>
        ) : element.parseTarget ? (
          <tr>
            <td>{element.label}</td>
            <td>{renderTargetMarket({ element })}</td>
          </tr>
        ) : (
          <tr key={index}>
            <td>{element.label}</td>
            <td>
              <div className="description-text">
                {element.name === 'product_image'
                  ? renderimagesURLS(element)
                  : tableDescription(searchResponse, element)}
              </div>
            </td>
          </tr>
        )}
      </div>
    ));
  return (
    <div className="gtin-table-style">
      <table>
        <tbody>
          {renderTableData({
            data: productLabelsAndNames,
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GTINView;
