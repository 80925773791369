import React, { useState, useContext } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import searchResultsRedux from '../../redux/searchResult';
import BreakpointsContext from '../../theme/context';
import Message from '../../components/Common/Message/Message';
import Loadable from '../../components/Common/Loadable';
import { useMedia } from 'react-use';
import './BulkSearch.scss';

const BulkSearch = () => {
  const searchResultsErrors = useSelector(searchResultsRedux.selectors.getErrors);
  const searchResultsLoading = useSelector(searchResultsRedux.selectors.getLoadingState);
  const [gtins, setGtins] = useState('');
  const [fileName, setFileName] = useState();
  const [gtinsFile, setGtinsFile] = useState();
  const dispatch = useDispatch();
  const Appcontext = useContext(BreakpointsContext);
  const { breakpoints } = Appcontext;
  const medium = useMedia(`(max-width: ${breakpoints.$md})`);
  const colSpan = medium ? 24 : 12;

  const handleSearch = () => {
    const gtinsSplitted = gtins.split(/\r?\n/);
    dispatch(
      searchResultsRedux.thunks.createBulkSearchResult({
        query: gtinsSplitted.join(',').trim(),
        file: gtinsFile,
      }),
    );
  };

  const handleClear = () => {
    setGtins('');
  };

  const handleChangeGtins = e => {
    setGtins(e.target.value);
  };

  const handleGtinsFile = event => {
    setFileName(event.target.files[0]?.name);
    setGtinsFile(event.target.files[0]);
  };

  return (
    <div className="bulk-search-styling">
      <div className="screen-width">
        <Loadable spinning={searchResultsLoading}>
          <div class="bulk-search">Bulk Search</div>
          {searchResultsErrors && <Message className="error" data={searchResultsErrors} />}
          <div class="top-description">
            Search for up to 1000 barcode numbers by copy/pasting the data or uploading a file below
          </div>
          <div className="formItemBulkSearch">
            <Form.Item>
              <Input.TextArea
                rows={7}
                placeholder={`Example: \n6225000138100\n6223000277638\n049000052350\n049000050103`}
                value={gtins}
                onChange={handleChangeGtins}
              />
            </Form.Item>
          </div>
          <div class="drag-and-drop">
            <Row>
              <Col span={colSpan}>
                <div class="allowed-text">Allowed file extensions are: xlsx</div>
                <input type="file" class="custom-file-input" onChange={handleGtinsFile} accept=".xlsx" />
                <div className="file-input-text">{fileName ? fileName : 'No file chosen'}</div>
              </Col>
              <Col span={colSpan}>
                <div class="drag-drop">Use this template to upload barcodes</div>
                <a
                  href={
                    'https://ams3.digitaloceanspaces.com/staging-storage/bulk_gtin_search_2.xlsx' ||
                    process.env.REACT_APP_XLSX_TEMPLATE
                  }
                  className="download-button"
                >
                  Download Template
                </a>
              </Col>
            </Row>
          </div>
          <Row className="buttons-row">
            <Form.Item className="search-button-style">
              <Button onClick={handleSearch}>Search</Button>
            </Form.Item>
            <Form.Item className="clear-button-style">
              <Button className="no-margin ant-btn" onClick={handleClear}>
                Clear
              </Button>
            </Form.Item>
          </Row>
        </Loadable>
      </div>
    </div>
  );
};

export default BulkSearch;
